import { useSignal } from '@preact/signals';
import { ComponentChild } from 'preact';
import Booleanify from 'api/types/Booleanify.ts';
import MaybeSignal, { isSignal } from 'api/types/MaybeSignal.ts';

export default function Label({ content, input, required }: {
    content: MaybeSignal<ComponentChild>;
    input?: MaybeSignal<string | undefined>;
    required?: MaybeSignal<boolean | 'true' | 'false' | 'hidden' | { text: string } | undefined>;
}) {
    if (!isSignal(content)) {
        content = useSignal(content);
    }

    if (!isSignal(input)) {
        input = useSignal(input);
    }

    if (!isSignal(required)) {
        required = useSignal(required ?? false);
    }

    const sup = required.value != 'hidden' && Booleanify(required) && (
        <sup
            className={`relative ${
                (typeof required!.value ==
                                'object' &&
                            required!.value.text
                        ? required!.value.text.trim()
                        : '*').length > 1
                    ? 'ml-0.5 mb-[0.75rem]'
                    : 'top-[10%] text-2xl'
            } leading-[75%] bold text-red-600`}
        >
            {typeof required.value == 'object' && required.value.text ? required.value.text.trim() : '*'}
        </sup>
    );

    return input.value
        ? (
            <p className='block text-sm font-medium leading-6 text-gray-900'>
                {content}
                {sup}
            </p>
        )
        : (
            <label
                htmlFor={input}
                className='block text-sm font-medium leading-6 text-gray-900'
            >
                {content}
                {sup}
            </label>
        );
}
