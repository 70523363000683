import Button, { ButtonProps } from 'components/button/Button.tsx';
import { cloneElement, ForwardedRef, forwardRef, JSX, MutableRefObject, RefObject, useEffect, useRef } from 'react-dom';
import MaybeSignal, { isReadOnlySignal, isSignal, Signalify } from 'api/types/MaybeSignal.ts';
import Booleanify from 'api/types/Booleanify.ts';
import { SliderProps } from 'islands/ui/Slider.tsx';
import Some from 'api/types/Some.ts';
import { IS_BROWSER } from '$fresh/runtime.ts';
import PasswdChecker from 'islands/ui/PasswdChecker.tsx';
import IconSend from 'tabler-icons/send.tsx';
import { HydroInputType } from 'islands/form/HydroInput.tsx';
import SignalLike from 'api/types/SignalLike.ts';
import { Unsignalify } from 'api/types/MaybeSignal.ts';
import { computed, useSignal } from '@preact/signals';
import Arrayify from 'api/types/Arrayify.ts';
import IconSearch from 'tabler-icons/search.tsx';
import EmailChecker from 'api/email/EmailChecker.ts';
import RichTextEditor from 'islands/editor/RichTextEditor.tsx';
import HydroButton from 'islands/button/HydroButton.tsx';
import IconEye from 'tabler-icons/eye.tsx';
import IconEyeOff from 'tabler-icons/eye-off.tsx';
import Label from 'components/form/Label.tsx';
import ExtendStrings from 'api/types/String.ts';

const InputType = [
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'rich',
    'search',
    'submit',
    'tel',
    'text',
    'textarea',
    'time',
    'url',
    'week',
] as const;

export type InputType = typeof InputType[number];

const ButtonLikeInputType = ['button', 'reset', 'submit'] as const;
export type ButtonLikeInputType = Extract<
    InputType,
    typeof ButtonLikeInputType[number]
>;

const TextLikeInputType = [
    'email',
    'number',
    'password',
    'range',
    'rich',
    'search',
    'tel',
    'text',
    'textarea',
    'time',
    'url',
] as const;
export type TextLikeInputType = Extract<
    InputType,
    typeof TextLikeInputType[number]
>;

export interface BoxLikeInputProps {
    type: 'checkbox' | 'radio';
    checked?: boolean | 'true' | 'false';
}

export type ButtonLikeInputProps =
    & { type: ButtonLikeInputType; value: never; required: never }
    & ButtonProps;

export type TextLikeInputProps =
    & {
        type: TextLikeInputType;
    }
    & Pick<
        JSX.HTMLAttributes<HTMLInputElement>,
        'autocomplete' | 'minLength' | 'maxLength'
    >;

export type HiddenInputProps = { type: 'hidden' } & {
    value?: MaybeSignal<Some<string> | number | undefined>;
};

export interface NumberInputProps {
    type: 'number';
    min?: number;
    max?: number;
    step?: number;
}

export interface PasswordInputProps {
    type: 'password';
    strength?: boolean | 'true' | 'false';
    confirm?: boolean | 'true' | 'false' | Exclude<string, 'true' | 'false'>;
}

export type RangeInputProps = { type: 'range' } & SliderProps;

export type RichTextEditorProps = {
    type: 'rich';

    inline?: boolean | 'true' | 'false';

    value?: MaybeSignal<string>;
    content?: SignalLike<[string, string]>;
};

export type SearchInputProps<T = any> = {
    type: 'search';

    items: MaybeSignal<Some<T>>;

    search: {
        match: (item: T, value: string) => boolean;
        display?: (item: T) => string | JSX.Element;
        selected?: (item?: T) => string;

        icon?: boolean | 'true' | 'false';
    };
};

export type InputProps<T = any> =
    & (
        { type: 'submit'; name?: string } | {
            type?: Exclude<InputType, 'submit'>;
            name: string;
        }
    )
    & {
        title?:
            | Exclude<string, 'true' | 'false'>
            | JSX.Element
            | boolean
            | 'true'
            | 'false';

        value?: MaybeSignal<T | undefined>;

        required?: boolean | 'true' | 'false' | 'hidden' | 'show' | {
            value?: boolean | 'true' | 'false' | 'hidden' | 'show';
            text?: string;
        };
        readonly?: MaybeSignal<boolean | 'true' | 'false'>;
        disabled?: MaybeSignal<boolean | 'true' | 'false'>;
        placeholder?: string;
        // deno-lint-ignore no-explicit-any
        onInput?: (e: Event) => any | (() => any);
        onKeyUp?: (e: KeyboardEvent) => any | (() => any);
    }
    & (
        | {
            type?: Exclude<
                InputType,
                | 'button'
                | 'hidden'
                | 'number'
                | 'password'
                | 'range'
                | 'reset'
                | 'submit'
            >;
        }
        | BoxLikeInputProps
        | ButtonLikeInputProps
        | HiddenInputProps
        | NumberInputProps
        | PasswordInputProps
        | RangeInputProps
        | SearchInputProps
        | TextLikeInputProps
    );

const Input = forwardRef(
    // deno-lint-ignore no-explicit-any
    function <T = any>(
        props: InputProps,
        ref?: ForwardedRef<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>,
    ) {
        if (IS_BROWSER) {
            ExtendStrings();
        }

        props.type ??= 'text';

        if (props.type != 'submit' && !props.name) {
            throw new Error(
                "Input fields must have a name (except those of type 'submit').",
            );
        }

        props.name ??= props.type;

        if (
            HydroInputType.includes(props.type as HydroInputType) &&
            (props.type != 'password' || 'strength' in props ||
                'confirm' in props) &&
            !IS_BROWSER
        ) {
            console.warn(
                `Input fields of type '${props.type}'${
                    props.type == 'password' ? ' with strength or confirm' : ''
                } should be rendered as <HydroInput /> elements.`,
            );
        }

        if (isSignal(props.disabled)) {
            if (!isReadOnlySignal(props.disabled)) {
                props.disabled.value = Booleanify(props.disabled);
            }
        } else {
            props.disabled = Signalify(Booleanify(props.disabled));
        }

        if (isSignal(props.readonly)) {
            if (!isReadOnlySignal(props.readonly)) {
                props.readonly.value = Booleanify(props.readonly);
            }
        } else {
            props.readonly = Signalify(Booleanify(props.readonly));
        }

        props.value ??= useSignal<T | undefined>(undefined);
        if (!isSignal(props.value)) {
            props.value = Signalify<T | undefined>(props.value);
        }

        if ('items' in props) {
            if (isSignal<T>(props.items)) {
                if (!isReadOnlySignal<T>(props.items)) {
                    (props.items as SignalLike<any>).value = Arrayify(
                        (props.items as SignalLike<T>).value,
                    )!;
                }
            } else {
                props.items = useSignal<any[]>(Arrayify(props.items)!);
            }
        }

        let strength: RefObject<HTMLDivElement> | null = null;
        if (props.type == 'password' && Booleanify(props.strength)) {
            strength = useRef<HTMLDivElement>(null);

            useEffect(() => {
                if (
                    !((ref as MutableRefObject<HTMLInputElement>).current &&
                        strength?.current)
                ) {
                    return;
                }

                const handler = () => {
                    const refRect = (ref as MutableRefObject<HTMLInputElement>)
                        .current!.getBoundingClientRect();
                    const strengthRect = strength!.current!
                        .getBoundingClientRect();

                    strength!.current!.style.left = `${Math.abs(refRect.left - strengthRect.left)}px`;
                    strength!.current!.style.top = `${Math.abs(refRect.bottom - strengthRect.top)}px`;
                    strength!.current!.style.width = `${refRect.width}px`;
                    strength!.current!.classList.add('mt-1');
                };

                handler();
                addEventListener('load', handler);
                addEventListener('resize', handler);

                return () => {
                    removeEventListener('load', handler);
                    removeEventListener('resize', handler);
                };
            }, [
                (ref as MutableRefObject<HTMLInputElement>).current,
                strength?.current,
            ]);
        }

        const title = (typeof props.title === 'string' ||
                typeof props.title === 'boolean') &&
                Booleanify(props.title)
            ? typeof props.title == 'boolean' ||
                    props.title === 'true'
                ? props.name.trim().capitalize().replaceAll(
                    '_',
                    ' ',
                ).replaceAll('-', ' ')
                : props.title.trim()
            : props.title;

        const label = () => (
            <Label
                content={title}
                input={['rich', 'radio', 'checkbox'].includes(props.type as string) ? undefined : props.name}
                required={props.required}
            />
        );

        const button = (Object.values(ButtonLikeInputType) as string[]).includes(
                props.type as string,
            )
            ? (
                <div className='grid grid-cols-1 justify-items-center'>
                    <Button
                        {...Object.assign(
                            {
                                ...(props as ButtonLikeInputProps),
                                disabled: Booleanify(props.disabled),
                                bounce: false,
                                ref,
                            },
                            (() => {
                                const o: Record<string, unknown> = {};

                                if (
                                    (typeof props.title === 'string' ||
                                        typeof props.title === 'boolean') &&
                                    Booleanify(props.title)
                                ) {
                                    o['title'] = title;
                                } else {
                                    o['icon'] = typeof props.title == 'object'
                                        ? props.title as JSX.Element
                                        : <IconSend />;
                                }

                                return o;
                            })(),
                        )}
                    />
                </div>
            )
            : <></>;
        const [reset, submit] = [button, button];

        const checkbox = props.type == 'checkbox' || props.type == 'radio'
            ? (
                <div className='h-fit w-full flex justify-center gap-x-3'>
                    <div className='flex justify-center items-center h-6'>
                        <input
                            id={props.name + '_' +
                                JSON.stringify(props.value?.value ?? '').replace(/^"(.*(?="$))"$/, '$1')}
                            name={props.name}
                            type={props.type}
                            value={props.value?.value}
                            className={`h-4 w-4 ${
                                props.type == 'checkbox' ? 'rounded' : ''
                            } border-gray-300 text-dspace focus:ring-dspace`}
                            checked={(() => {
                                const value = (props as BoxLikeInputProps).checked =
                                    Booleanify((props as BoxLikeInputProps).checked) || Booleanify(props.value);

                                try {
                                    if (!isReadOnlySignal(props.value)) {
                                        props.value.value = value;
                                    }
                                } catch (e) {
                                    console.error(e);
                                }

                                return value;
                            })()}
                            required={Booleanify(
                                typeof props.required == 'object' ? props.required.value : props.required,
                            ) && props.required != 'show'}
                            disabled={props.disabled as SignalLike<boolean>}
                            readOnly={props.readonly as SignalLike<boolean>}
                            autoFocus={'autofocus' in props &&
                                    props.autofocus
                                ? props.autofocus
                                : false}
                            onInput={(e) => {
                                try {
                                    if (
                                        e.target && 'checked' in e.target && isSignal(props.value) &&
                                        !isReadOnlySignal(props.value)
                                    ) {
                                        props.value!.value = (e.target as HTMLInputElement).checked;
                                    }
                                } catch (e) {
                                    console.error(e);
                                }

                                props.onInput?.(e);
                            }}
                        />
                    </div>

                    {label()}
                </div>
            )
            : <></>;
        const radio = checkbox;

        const text = (
            name: string = props.name!,
            iref: typeof ref = ref!,
        ) => (Object.values(TextLikeInputType) as string[]).includes(
                props.type as string,
            )
            ? (() => {
                const show = IS_BROWSER && props.type == 'password' && !((agent) =>
                        agent.indexOf('edge') > -1 || agent.indexOf('edg/') > -1)(
                            window.navigator.userAgent.toLowerCase(),
                        )
                    ? useSignal(false)
                    : undefined;

                const onInput = props.type == 'rich' ? props.onInput : (e: Event) => {
                    if (e.target && 'value' in e.target) {
                        const t = props.type == 'textarea'
                            ? (e.target as HTMLTextAreaElement).value
                            : props.type == 'number'
                            ? (e.target as HTMLInputElement).valueAsNumber
                            : (e.target as HTMLInputElement).value.trimStart();
                        e.target.value = t;

                        if (
                            !(props.type == 'search' ||
                                (props.type == 'password' && name == `${props.name}_confirm` ||
                                    isReadOnlySignal(props.value)))
                        ) {
                            (props.value as SignalLike).value = props.type == 'number'
                                ? (Number.parseFloat(t.toString()) - Number.parseInt(t.toString()) <= Number.EPSILON
                                    ? Number.parseInt(t.toString())
                                    : Number.parseFloat(t.toString()))
                                : t;
                        }

                        props.onInput?.(e);
                    }
                };

                const iprops = {
                    ...(props.type == 'number'
                        ? {
                            min: (props as NumberInputProps).min,
                            max: (props as NumberInputProps).max,
                            step: (props as NumberInputProps).step,
                        }
                        : {}),
                    width: props.width,
                    height: props.height,
                    minLength: props.minLength,
                    maxLength: props.maxLength,
                    ref: iref as ForwardedRef<HTMLInputElement>,
                    readOnly: props.readonly as SignalLike<boolean>,
                    disabled: props.disabled as SignalLike<boolean>,
                    autoFocus: 'autofocus' in props &&
                            props.autofocus
                        ? props.autofocus
                        : false,
                    type: ['search', 'textarea', 'rich'].includes(props.type ?? 'text') || show?.value
                        ? 'text'
                        : props.type,
                    name: name,
                    id: name,
                    autoComplete: 'autocomplete' in props &&
                            props.autocomplete
                        ? props.autocomplete
                        : 'off',
                    onkeydown: (e: KeyboardEvent) => {
                        const ret = props.onKeyDown?.(e);
                        if (typeof ret == 'boolean') {
                            return ret;
                        }
                    },
                    onkeyup: (e: KeyboardEvent) => {
                        const ret = props.onKeyUp?.(e);
                        if (typeof ret == 'boolean') {
                            return ret;
                        }
                    },
                    oncopy: (e: ClipboardEvent) => {
                        const target = e.target as HTMLInputElement | null;
                        if (!target) {
                            return;
                        }

                        const val = target.value;

                        e.clipboardData?.setData(
                            'text/plain',
                            val.substring(target.selectionStart ?? 0, target.selectionEnd ?? val.length),
                        );
                        e.preventDefault();
                    },
                    onpaste: (e: ClipboardEvent) => {
                        e.preventDefault();

                        const target = e.target as HTMLInputElement | null;
                        if (!target) {
                            return;
                        }

                        let { selectionStart, selectionEnd, value } = target;
                        selectionStart ??= 0;
                        selectionEnd ??= value.length;

                        value = value.substring(0, selectionStart) + (e.clipboardData?.getData('text/plain') ?? '') +
                            value.substring(selectionEnd);

                        target.value = value;
                        target.dispatchEvent(new Event('input', { bubbles: true }));
                    },
                    onInput,
                    required: Booleanify(
                        typeof props.required == 'object' ? props.required.value : props.required,
                    ) &&
                        ((typeof props.required == 'object' ? props.required.value : props.required) !== 'show'),
                    value: computed(() => {
                        let v = (props.type == 'password' && name == `${props.name}_confirm`)
                            ? (iref as MutableRefObject<HTMLInputElement>)?.current?.value
                            : (Unsignalify(props.value) ??
                                (iref as MutableRefObject<HTMLInputElement>)?.current?.value);

                        v = props.type == 'search'
                            ? ((props as SearchInputProps).search
                                .selected?.(v) ??
                                v?.toString().trim())
                            : v;

                        return typeof v == 'string' ? v.trimStart() : v;
                    }),
                    className: `caret-dspace borderblock border-0 bg-transparent py-1.5 p${
                        props.type == 'search' ? 'x' : 'l'
                    }-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                        props.type == 'textarea' ? 'border-none outline-none' : ''
                    } ${
                        show
                            ? 'w-[92.5%]'
                            : props.type == 'number'
                            ? `text-right w-[${Math.max(Math.floor(Math.log10(props.max ?? 1_000_000)) * 2, 10)}ch]`
                            : 'w-full'
                    }`,
                    placeholder: props.placeholder,
                    style: {
                        '::ms-reveal': { display: 'none' },
                        '::ms-clear': { display: 'none' },
                        ...(props.type == 'number'
                            ? {
                                '-moz-appearance': 'textfield',
                                '::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    'margin': 0,
                                },
                                '::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    'margin': 0,
                                },
                            }
                            : {}),
                    },
                };

                const i = props.type == 'textarea'
                    ? <textarea {...(iprops as InputProps)}>{iprops.value}</textarea>
                    : <input {...(iprops as InputProps)} />;

                if (props.type == 'search') {
                    (props as SearchInputProps).search.display ??= (x) => (x ?? '').toString();
                    (props as SearchInputProps).search.selected ??= (x) => (x ?? '').toString();

                    return (
                        <div className='w-full min-w-[200px]'>
                            <div
                                className={`w-full relative ${
                                    (props as SearchInputProps).search.icon ? 'ml-8' : ''
                                } grid grid-cols-1 justify-items-center items-center`}
                            >
                                <div
                                    className={`w-full flex ${
                                        (props as SearchInputProps).search
                                                .icon
                                            ? 'w-[calc((100%-4rem)-1px)]'
                                            : ''
                                    } rounded-md ${
                                        (props as SearchInputProps).search
                                                .icon
                                            ? 'rounded-r-none'
                                            : ''
                                    } shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-dspace sm:max-w-md`}
                                >
                                    {i}
                                </div>

                                {(props as SearchInputProps).search.icon &&
                                    (
                                        <div className='relative flex items-center right-0 h-full w-fit bg-ghostwhite border border-l-0 rounded-r-md'>
                                            <div className='h-full transform scale-x-[-1]'>
                                                <IconSearch class='m-[0.5625em] w-4 h-full' />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    );
                }

                return (
                    <div
                        onFocus={() => {
                            (iref as MutableRefObject<HTMLInputElement>)?.current?.focus();
                        }}
                        className={`relative ${
                            props.type == 'number' ? 'w-fit pr-1.5' : 'w-full min-w-[200px]'
                        } flex flex-row flex-wrap items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-dspace sm:max-w-md`}
                    >
                        {i}
                        {show && (
                            <div className='absolute grid grid-cols-1 items-center justify-items-center right-0 z-50 w-[7.5%]'>
                                <HydroButton
                                    title={computed(() => `${show.value ? 'Hide' : 'Show'} the password.`)}
                                    icon={show.value ? <IconEyeOff class='w-4 h-4' /> : <IconEye class='w-4 h-4' />}
                                    onClick={() => {
                                        show.value = !show.value;
                                    }}
                                    bounce={false}
                                    color={{ bg: 'inherit', text: 'dspace' }}
                                />
                            </div>
                        )}
                    </div>
                );
            })()
            : <></>;

        const validatePassword = props.type == 'password'
            ? (e: Event) => {
                const p = (e.target as HTMLInputElement).form ??
                    ((e.target as HTMLInputElement).id == `${props.name}`
                        ? (e.target as HTMLInputElement).parentElement!
                            .parentElement!.parentElement!
                        : props.title
                        ? (e.target as HTMLInputElement).parentElement!
                            .parentElement!.parentElement!.parentElement!
                            .parentElement!
                        : (e.target as HTMLInputElement).parentElement!
                            .parentElement!.parentElement!.parentElement!);

                const [password, confirm, text] = ['', '_confirm', '_text'].map(
                    (
                        x,
                    ) => p.querySelector(`#${props.name}${x}`),
                );

                if (!(password && confirm && text)) {
                    return;
                }

                if (
                    (password as HTMLInputElement).value.length *
                                (confirm as HTMLInputElement).value.length ==
                        0 ||
                    (password as HTMLInputElement).value ===
                        (confirm as HTMLInputElement).value
                ) {
                    [password, confirm].forEach((x) => {
                        x.parentElement!.classList.remove(
                            '!ring-red-500',
                        );
                    });

                    text.classList.add('invisible');
                } else {
                    [password, confirm].forEach((x) => {
                        x.parentElement!.classList.add(
                            '!ring-red-500',
                        );
                    });

                    text.classList.remove('invisible');
                }
            }
            : (e: Event) => {};

        const validateEmail = props.type == 'email'
            ? (e: Event) => {
                const p = (e.target as HTMLInputElement).form ??
                    (e.target as HTMLInputElement).parentElement!
                        .parentElement!.parentElement!;

                const [email, text] = ['', '_text'].map((x) => p.querySelector(`#${props.name}${x}`));

                if (!(email && text)) {
                    return;
                }

                if (
                    (email as HTMLInputElement).value.length == 0 ||
                    EmailChecker.valid((email as HTMLInputElement).value)
                ) {
                    (email as HTMLInputElement).parentElement!.classList.remove(
                        '!ring-red-500',
                    );

                    text.classList.add('invisible');
                } else {
                    (email as HTMLInputElement).parentElement!.classList.add(
                        '!ring-red-500',
                    );

                    text.classList.remove('invisible');
                }
            }
            : (e: Event) => {};

        // TODO: Add support for other input types.
        // TODO: fix input field dimensions.
        // TODO: fix typing.
        return Object.fromEntries(
            Object.entries({
                button,
                checkbox,
                color: <></>,
                date: <></>,
                'datetime-local': <></>,
                email: (
                    <div className='w-full grid grid-cols-1 justify-items-center'>
                        {cloneElement(text(), { oninput: validateEmail })}

                        <p
                            id={`${props.name}_text`}
                            className='invisible text-md text-red-600 mt-2'
                        >
                            Invalid email address.
                        </p>
                    </div>
                ),
                file: <></>,
                hidden: (
                    <input
                        type='hidden'
                        id={props.name}
                        name={props.name}
                        value={(props as HiddenInputProps).value}
                    />
                ),
                image: <></>,
                month: <></>,
                number: text(),
                password: (
                    <div className='w-full grid grid-cols-1 justify-items-center space-y-6'>
                        {'strength' in props && props.strength
                            ? (
                                <div className='w-full grid grid-cols-1 justify-items-center items-baseline relative'>
                                    {cloneElement(text(), {
                                        oninput: validatePassword,
                                    })}

                                    <PasswdChecker
                                        ref={strength}
                                        field={props.name}
                                    />
                                </div>
                            )
                            : cloneElement(text(), {
                                oninput: validatePassword,
                            })}
                        {'confirm' in props &&
                            Booleanify(props.confirm) &&
                            (
                                <>
                                    {props.title
                                        ? (
                                            <div className='w-full grid grid-cols-1 justify-items-center'>
                                                <label
                                                    htmlFor={props
                                                        .name +
                                                        '_confirm'}
                                                    className='block text-sm font-medium leading-6 text-gray-900'
                                                >
                                                    {typeof props
                                                                    .confirm ==
                                                                'string' &&
                                                            props
                                                                    .confirm !==
                                                                'true' &&
                                                            props
                                                                    .confirm !==
                                                                'false'
                                                        ? props.confirm
                                                        : 'Confirm password'}
                                                </label>

                                                <div className='w-full grid grid-cols-1 justify-items-center mt-2'>
                                                    {cloneElement(
                                                        text(
                                                            props.name +
                                                                '_confirm',
                                                            useRef<
                                                                HTMLInputElement
                                                            >(null),
                                                        ),
                                                        {
                                                            oninput: validatePassword,
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        )
                                        : cloneElement(
                                            text(
                                                props.name + '_confirm',
                                                useRef<HTMLInputElement>(
                                                    null,
                                                ),
                                            ),
                                            {
                                                oninput: validatePassword,
                                            },
                                        )}

                                    <p
                                        id={`${props.name}_text`}
                                        className='invisible text-md text-red-600 mt-2'
                                    >
                                        The passwords do not match.
                                    </p>
                                </>
                            )}
                    </div>
                ),
                radio,
                range: <></>,
                reset,
                rich: (
                    <RichTextEditor
                        {...(props as RichTextEditorProps)}
                        ref={(ref ??
                            useRef<HTMLDivElement>(null)) as ForwardedRef<
                                HTMLDivElement
                            >}
                    />
                ),
                search: text(),
                submit,
                tel: <></>,
                text: text(),
                textarea: text(),
                time: <></>,
                url: <></>,
                week: <></>,
            }).map((
                [k, v],
            ): [InputType, JSX.Element] => [
                k as InputType,
                [
                        'email',
                        'number',
                        'password',
                        'range',
                        'rich',
                        'search',
                        'tel',
                        'text',
                        'textarea',
                        'time',
                        'url',
                    ].includes(k) && Booleanify(props.title)
                    ? (
                        <div className='w-full sm:col-span-4 grid grid-cols-1 items-center justify-items-center h-fit'>
                            {label()}
                            <div className='w-full inline-grid grid-cols-1 items-center justify-items-center mt-2'>
                                {v}
                            </div>
                        </div>
                    )
                    : (
                        <div className='w-full grid grid-cols-1 justify-items-center h-fit'>
                            {v}
                        </div>
                    ),
            ]),
        )[props.type as InputType];
    },
);

export default Input;
