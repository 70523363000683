import Input, { InputProps, InputType } from 'components/form/Input.tsx';
import { IS_BROWSER } from '$fresh/runtime.ts';
import { ForwardedRef, forwardRef, MutableRefObject, Ref, useEffect, useRef } from 'react-dom';
import { isReadOnlySignal, isSignal, Signalify, Unsignalify } from 'api/types/MaybeSignal.ts';
import Arrayify from 'api/types/Arrayify.ts';
import { ComponentChildren } from 'preact';
import { createPortal, render } from 'preact/compat';
import SignalLike from 'api/types/SignalLike.ts';
import { useSignal } from '@preact/signals';
import HashCode from 'hash/HashCode.ts';

export const HydroInputType = [
    'email',
    'password',
    'range',
    'rich',
    'search',
] as const;
export type HydroInputType = Extract<
    InputType,
    typeof HydroInputType[number]
>;

const HydroInput = forwardRef(
    function <T = any>(
        props: InputProps<T> & { children?: ComponentChildren },
        ref?: ForwardedRef<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>,
    ) {
        if (!IS_BROWSER) return <></>;

        if (props.children) {
            props.title = props.children;
        }

        ref ??= useRef<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>(null);
        let r2: Ref<HTMLDivElement> | undefined = undefined;

        if (props.type == 'search') {
            r2 = useRef<HTMLDivElement>(null);

            if (!isSignal(props.value)) {
                props.value = useSignal<T | undefined>(undefined);
            }

            if (isSignal(props.items)) {
                if (!isReadOnlySignal(props.items)) {
                    props.items.value = Arrayify(props.items.value)!;
                }
            } else {
                props.items = Signalify(Arrayify(props.items)!);
            }

            useEffect(() => {
                (ref as MutableRefObject<HTMLInputElement>).current
                    ?.addEventListener(
                        'input',
                        () => {
                            if (!r2!.current) return;

                            (props.value as SignalLike<T | undefined>).value = undefined;

                            (ref as MutableRefObject<HTMLInputElement>).current!
                                .value = (ref as MutableRefObject<HTMLInputElement>)
                                    .current!.value
                                    .trimStart();

                            r2!.current!.replaceChildren();

                            if (
                                (ref as MutableRefObject<HTMLInputElement>)
                                    .current!
                                    .value
                                    .length == 0
                            ) return;

                            render(
                                createPortal(
                                    <>
                                        {(props.items as SignalLike<T[]>).value
                                            .filter((x) =>
                                                props.search.match(
                                                    x,
                                                    (ref as MutableRefObject<
                                                        HTMLInputElement
                                                    >)
                                                        .current!.value,
                                                )
                                            )
                                            .map((x, i) => {
                                                const e = props.search.display!(
                                                    x,
                                                );

                                                return (
                                                    <div
                                                        key={props.name +
                                                            '_result_' + i +
                                                            HashCode.bind(e)()}
                                                        className='rounded-sm border hover:bg-gray-100 cursor-pointer select-none'
                                                        data={JSON.stringify(x)}
                                                    >
                                                        {typeof e === 'string' ? <p>{e}</p> : <>{e}</>}
                                                    </div>
                                                );
                                            })}
                                    </>,
                                    r2!.current!,
                                ),
                                r2!.current!,
                            );

                            Array.from(r2!.current.children).forEach((x) =>
                                x.addEventListener(
                                    'click',
                                    () => {
                                        x.parentElement?.replaceChildren();

                                        (props.value as SignalLike<
                                            T | undefined
                                        >)
                                            .value = JSON.parse(
                                                x.getAttribute('data')!,
                                            );

                                        if (
                                            (ref as MutableRefObject<
                                                HTMLInputElement
                                            >)
                                                .current
                                        ) {
                                            (ref as MutableRefObject<
                                                HTMLInputElement
                                            >)
                                                .current.value = ((props.value as SignalLike<
                                                        T | undefined
                                                    >).value
                                                    ? props.search.selected?.(
                                                        Unsignalify(props.value as SignalLike<T | undefined>),
                                                    )
                                                    : '')?.trim();
                                        }
                                    },
                                )
                            );
                        },
                    );
            }, [(ref as MutableRefObject<HTMLInputElement>).current]);

            return (
                <div>
                    <Input {...props} ref={ref} />{' '}
                    <div ref={r2}>
                    </div>
                </div>
            );
        }

        return <Input {...props} ref={ref} />;
    },
);

export default HydroInput;
